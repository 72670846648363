import { GrowthBook as ServerSideGrowthBook } from '@growthbook/growthbook';

const config = {
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  enableDevMode: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
};

export const initServerGrowthBook = (): ServerSideGrowthBook =>
  new ServerSideGrowthBook({
    ...config,
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_API_CLIENT_KEY,
  });
