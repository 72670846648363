'use client';

import { FeatureApiResponse, GrowthBookProvider } from '@growthbook/growthbook-react';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from 'ui';

import { useSetAttributesOnAuthChange } from '../hooks';
import { clientGrowthbook } from '../utils/growthbook';

// Let the GrowthBook instance know when the URL changes so the active
const updateGrowthBookURL = () => {
  clientGrowthbook.setURL(window.location.href);
};

const getQueryParams = () => window.location.search;

const FeatureFlagsProvider = ({ children, payload }: { children: React.ReactNode; payload: FeatureApiResponse }) => {
  const pathname = usePathname();
  const [isReady, setIsReady] = useState(false);

  useSetAttributesOnAuthChange();

  const gb = useMemo(
    () =>
      clientGrowthbook.initSync({
        payload,
        streaming: true,
      }),
    [payload]
  );

  useEffect(() => {
    gb.setAttributes({ ...gb.getAttributes, url: getQueryParams() });
    setIsReady(true);

    // Subscribe to route change events and update GrowthBook
    updateGrowthBookURL();
  }, [gb, pathname]);

  return isReady ? <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider> : <Loader />;
};

export default FeatureFlagsProvider;
