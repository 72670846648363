'use client';

import { bgLocale, createDate, enLocale, TokiDate } from 'dates';
// eslint-disable-next-line no-restricted-imports
import { useSelectedLanguage } from 'next-export-i18n';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDayjs, DatePicker, LocalizationProvider } from 'ui';

import { getError } from '../../utils';

interface Props {
  label: string;
  name: string;
  autocomplete?: string;
  disabled?: boolean;
  minDate?: TokiDate | undefined;
  maxDate?: TokiDate | undefined;
  onBlur?: () => void;
}

export const MonthAndYearInput = ({ label, name, autocomplete, minDate, maxDate, onBlur, disabled = false }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { lang } = useSelectedLanguage();

  const error = getError(errors, name);

  const [locale, setLocale] = useState(bgLocale.name);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (lang === 'bg') {
      setLocale(bgLocale.name);
    }

    if (lang === 'en') {
      setLocale(enLocale.name);
    }
  }, [lang]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            disabled={disabled}
            views={['month', 'year']}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={createDate(field.value)}
            onChange={(newValue: TokiDate | null) => {
              field.onChange(newValue);

              if (onBlur) {
                onBlur();
              }
            }}
            slotProps={{
              textField: {
                error: Boolean(error),
                fullWidth: true,
                helperText: error?.message,
                label,
                name: autocomplete || name,
                onClick: () => setOpen(true),
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
