/* eslint-disable no-console */

'use client';

import { Attributes } from '../types';
import { clientGrowthbook } from './growthbook';

export const setAttributes = (attributes: Attributes) => {
  console.debug('Setting feature flag attributes', attributes);
  clientGrowthbook.setAttributes({ ...clientGrowthbook.getAttributes(), ...attributes });
};
