'use client';

import { IfFeatureEnabled as GrowthBookIfFeatureEnabled } from '@growthbook/growthbook-react';

import { AppFeatures } from '../types';

type Props = {
  children: React.ReactNode;
  feature: keyof AppFeatures;
};

const IfFeatureEnabled = ({ feature, children }: Props): JSX.Element | null => {
  return <GrowthBookIfFeatureEnabled feature={feature}>{children}</GrowthBookIfFeatureEnabled>;
};

export default IfFeatureEnabled;
