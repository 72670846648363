'use client';

import { GrowthBook as ClientSideGrowthBook } from '@growthbook/growthbook-react';
import { addFeatureFlagEvaluation } from 'rum';

import { setInitialFeaturesInRum } from './setInitialFeaturesInRum';

const config = {
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  enableDevMode: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
};

export const clientGrowthbook = new ClientSideGrowthBook({
  ...config,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  onFeatureUsage: (featureKey, result) => {
    addFeatureFlagEvaluation(featureKey, result.value);
  },
});

setInitialFeaturesInRum(clientGrowthbook);
